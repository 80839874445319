// Post login handler based on parameters
import {track} from "./analytics";
import {persistentUrl, wait} from "./index";
import {auth} from "./firebase";
import {getCheckoutUrl} from "./prices";
import {loginExtension, sendVerificationEmail} from "./authentication";
import { User } from "firebase/auth";
import { navigate } from "gatsby";

export async function processIntent(user: User, intent: string = '', params: any = {}) {
  switch (intent) {
    case 'ext_auth':
      // Close Window
      await wait(1000);
      window.close();
      return;
    case 'ext_verify_email':
      if (!user?.emailVerified) {
        await sendVerificationEmail(user);
        navigate(persistentUrl('/verify-email'))
      } else {
        await wait(1000);
        window.close();
      }
      return;
    case 'choose_plan':
      navigate(persistentUrl('/plans'));
      return;
    case 'checkout':
      const priceId = params.get('plan');
      const promo = params.get('promo');
      if (priceId) {
        await goToCheckout(priceId, auth?.currentUser?.uid || '', promo);
      }
      return;
    default:
      navigate('/account');
  }
}

export async function postLoginFlow(user: User) {
  await loginExtension();
  const params = new URLSearchParams(window.location.search);
  const intent = params.get('intent') || '';

  track('auth_success', {intent});
  await processIntent(user, intent, params);
}

const goToCheckout = async (priceId: string, uid: string, promoCode?: string) => {
  try {
    const url = await getCheckoutUrl(priceId, uid, promoCode);
    window.location.assign(url);
  } catch (error) {
    track('error_going_to_checkout', {error});
    console.error(error);
    // if we fail just take them to the account page
    navigate('/account');
  }
}