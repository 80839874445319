import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import {auth, functions} from "./firebase";
import {sendAuthenticateMessage} from "./extension";
import {track} from "./analytics";
import {persistentUrl} from "./index";
import { navigate } from "gatsby";

export async function loginExtension() {
  // gets custom token from server
  const fn = httpsCallable(functions, 'authenticate');
  const { data } = await fn();
  if (!data) return;
  sendAuthenticateMessage(data as string);
}

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
}

export async function signInWithEmail(email: string, password: string) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function signUpWithEmail(email: string, password: string) {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await sendVerificationEmail(userCredential.user);
  navigate(persistentUrl('/verify-email'))
}

export async function signOutUser() {
  await signOut(auth);
}

const THROTTLE_TIME = 30000; // 30 seconds
const LAST_SEND_KEY = 'last_email_verification';
const canSendEmail = (lastSendTime: number) => {
  if (!lastSendTime || isNaN(lastSendTime)) {
    return true;
  }
  return Date.now() - lastSendTime >= THROTTLE_TIME;
};

// sends verification email throttled
export async function sendVerificationEmail(user: User) {
  if (!user) {
    return;
  }
  const lastAutoSendTime = Number(localStorage.getItem(LAST_SEND_KEY));
  if (!canSendEmail(lastAutoSendTime)) {
    return;
  }
  try {
    await sendEmailVerification(user);
    track('verification_email_sent');
    localStorage.setItem(LAST_SEND_KEY, Date.now().toString());
    return;
  } catch (error) {
    track('error_sending_verification_email', {
      // @ts-ignore
      error: error?.code,
    })
    console.error(error);
  }
  return;
}