import React, {FormEvent, HTMLAttributes, useEffect, useLayoutEffect, useState } from "react";
import {persistentUrl} from "../utils";
import {Dot, Loader} from "./Logo";
import s from "./SignIn.module.scss";
import {auth, FIREBASE_AUTH_ERROR_MESSAGES} from "../utils/firebase";
import { onAuthStateChanged} from "firebase/auth";
import {Link} from "gatsby";
import {
  signInWithEmail,
  signInWithGoogle,
  signUpWithEmail
} from "../utils/authentication";
import googleSvg from "../assets/Google__G__Logo.svg";
import {track} from "../utils/analytics";
import {postLoginFlow} from "../utils/intent";

const LOCAL_ERRORS: { [key: string]: string} = {
  'form/no-email-or-password': 'You must enter an email and password to continue.',
  'form/no-password-confirmation': 'You must confirm your password.',
  'form/passwords-mismatched': 'Password and confirmation do not match.',
}

export interface SignInProps extends HTMLAttributes<HTMLFormElement> {
  signUp?: boolean;
}

export function SignIn({signUp, ...props}: SignInProps) {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Dynamically generated error that doesn't show until submit, and clears as normal errors
  let formError: string|undefined = undefined;
  if (signUp) {
    if (!confirmPassword) {
      formError = 'form/no-password-confirmation';
    }
    else if (password !== confirmPassword) {
      formError = 'form/passwords-mismatched';
    }
  }
  if (!email || !password) {
    formError = 'form/no-email-or-password';
  }

  useEffect(() => { // stop loading if there is an error
    error && setIsLoading(false)
  }, [error]);

  useEffect(() => { // clear error use user types
    if (error && email && password) {
      setError(undefined);
    }
  }, [email, password, confirmPassword]);

  useLayoutEffect(() => { // if login is successful trigger flow
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoading(true);
        postLoginFlow(user);
      }
    });
  }, []);


  const handleError = (error: any) => {
    const errorCode = error.code;
    const errorMessage = FIREBASE_AUTH_ERROR_MESSAGES[errorCode] || 'Something went wrong, please try again lager.';
    setError(errorMessage);
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    track('clicked_sign_in', { type: 'email', sign_up: signUp });
    if (formError) {
      track('error_signing_in', { type: 'email', sign_up: signUp, error: formError });
      setError(LOCAL_ERRORS[formError]);
      return;
    }
    // thi
    if (email && password) {
      setIsLoading(true);
      try {
        if (signUp) {
          return await signUpWithEmail(email, password);
        } else {
          return await signInWithEmail(email, password);
        }
      } catch (error) {
        // @ts-ignore
        track('error_signing_in', { type: 'email', sign_up: signUp, error: error?.code });
        handleError(error);
      }
    }
  }

  const onSignInWithGoogle = async () => {
    track('clicked_sign_in', { type: 'google', sign_up: signUp });
    setIsLoading(true);
    try {
      await signInWithGoogle();
    } catch (error) {
      // @ts-ignore
      track('error_signing_in', { type: 'google', sign_up: signUp, error: error?.code });
      handleError(error);
    }
  }

  const label = signUp ? 'Sign Up' : 'Sign In';
  const goToLabel = signUp ? 'Sign In' : 'Sign Up';
  const goToLink = signUp ? '/signin' : '/signup';
  const goToHint = signUp ? 'Already have an account?' : 'Don\'t have an account?';

  // TODO: removed this: <Link to='/terms'>Terms</Link> and
  // TODO: css modules
  return <form className={s.signIn} onSubmit={onSubmit} {...props}>
    <h1>{label}<Dot/></h1>
    {signUp && <p className='subtitle'>
      By signing up, I agree to BLUF's
      &nbsp;<Link to='/privacy'>Privacy Policy</Link>
    </p>}

    <div className='email-box'>
      <label htmlFor='email'>Email</label>
      <input
        name='email'
        placeholder='Your email'
        autoComplete="email"
        onChange={ev => setEmail(ev.target.value)}
      />

      <label htmlFor={signUp ? 'new-password' : 'current-password'}>Password</label>
      <input
        name={signUp ? 'new-password' : 'current-password'}
        type='password'
        placeholder='Your password'
        autoComplete={signUp ? 'new-password' : 'current-password'}
        onChange={ev => setPassword(ev.target.value)}
      />

      {signUp && <>
        <label htmlFor='confirm-new-password'>Confirm Password</label>
        <input
          name="confirm-new-password"
          type='password'
          placeholder='Confirm your password'
          autoComplete='new-password'
          onChange={ev => setConfirmPassword(ev.target.value)}
        />
      </>}

      {error && <div className='error'>{error}</div>}
      <button type='submit'>{label}</button>

      <div className='subtitle'>
        {goToHint} <Link to={persistentUrl(goToLink)}>{goToLabel}</Link>
      </div>
    </div>

    <div className='or'>
      <hr/>or<hr/>
    </div>

    <button type='button' className='secondary' onClick={onSignInWithGoogle}>
      <svg className="chrome">
        <image href={googleSvg}/>
      </svg>
      {label} With Google
    </button>

    {isLoading && <Loader />}
  </form>
}
